import WatchForChanges from 'glass/modules/watchforchanges'
import CanvasForms     from 'glass/modules/forms'

function isMassfinderMapPage() {
  return $('#map.massfinder-map').length > 0
}

const _file = 'Map';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var Map = (function($){
  var markers        = [],
      map_center     = {lat: 49.14465372459132, lng: -122.70808662821689},
      map_zoom       = 10,
      close_zoom     = 17,
      place_bounds   = false,
      parish_results = [],
      place_results  = [],
      map, search_timer, updating_markers;

  $(document).on('content-ready', _double_include ? null : function(e, element){
    if (!isMassfinderMapPage()) {
      return
    }

    $(element).find('.parish-result').click(function(){
      var parish_id = $(this).data('id');
      load_new_page({parish_id: parish_id}, "Parish Page", `/masstimes/parish/${parish_id}`, true);
    });

    $(element).find('.masstime-result').click(function(){
      var masstime_id = $(this).data('id');
      load_new_page({masstime_id: masstime_id}, "Parish Page", "/masstimes/" + masstime_id, true);
    });

    $(element).find('.place-result').click(function(){
      var query = $(this).find('span').text();
      $('.search-spinner').show();
      $('.search-clear').hide();
      $("#search").val(query);
      load_new_page({query: query}, 'Search', build_search_url(query), true);
      show_view('search-preview');
    });

    $(element).find('.btn-show-parishes').click(function(){
      show_view('search-preview');
    });

    $(element).find('.btn-hide-parishes').click(function(){
      show_view('main');
    });

    $(element).find(".search-nearby").click(function(){
      var $button = $(this);

      $(".search-nearby .icon-spinner").show();
      $(".search-nearby .icon-angle-right").hide();

      navigator.geolocation.getCurrentPosition(
        function(data){
          var location = {lat: data.coords.latitude, lng: data.coords.longitude}; //map_center;
          window.my_location = location;
          window.parishes = sort_parishes(window.parishes, location);
          parish_results = window.parishes.slice(0, 5);

          $(".search-nearby .icon-spinner").hide();
          $(".search-nearby .icon-angle-right").show();

          if (!map) {
            init_map(function(){
              update_markers(location);
            });
          } else {
            update_markers(location);
          }

          show_view('main');
        },
        function(err){ console.warn("error: " + err.message) }
      );
    });

    $(element).find(".search-clear button").click(function(){
      load_new_page({query: ''}, 'Search', '/search/', false);
      clear_search();
    });

    var watcher = new WatchForChanges.Watcher({
      'onkeypress': $(element).find("#search"),
      'onkeyup':    $(element).find("#search"),
      'onchange':   $(element).find('#search_form input[type="checkbox"], #search_form select'),
      'delay':      500,
      'maxdelay':   2000,
      'callback':   function(){
        if (!$("#search_form").hasClass('submitting')) {
          var query = $(element).find("#search").val();
          load_new_page({query: query}, 'Search', build_search_url(query), false);
          handle_search();
        }
      }
    });

    $(element).find(".search-start").click(start_search);

    $(element).find("#home_search").focus(start_search);

    $(element).find("#search_form").submit(function(){
      var service = new google.maps.places.PlacesService(map),
          query = $(element).find("#search").val();

      $("#search").blur();

      $("#search_form").addClass('submitting');

      load_new_page({query: query}, 'Search', build_search_url(query), false);
      handle_search();

      show_view('search-preview');

      return false;
    });

    if (window.location.pathname !== '/') {
      $(element).find("#search").focus();
    }
  });

  var handle_search = function(){
    $('.search-spinner').show();
    $('.search-clear').hide();

    window.current_parish_id = false;

    $("#search_form").removeClass('submitting');

    search_parishes(function(){
      search_places(function(){
        update_results();
      });
    });
  }

  var init_map = function(callback) {
    var onload = !map;

    updating_markers = true;

    $("#map").show();
    $("#home_page").remove();
    $(".show-home").removeClass('show-home');

    map = new google.maps.Map(document.getElementById('map'), {
      center:            map_center,
      zoom:              map_zoom,
      mapTypeControl:    false,
      scaleControl:      false,
      clickableIcons:    false,
      gestureHandling:   'greedy',
      streetViewControl: false,
      rotateControl:     false,
      fullscreenControl: false
    });

    // google.maps.event.addListener(map, 'center_changed', update_results_in_view);
    // google.maps.event.addListener(map, 'zoom_changed',   update_results_in_view);
    google.maps.event.addListener(map, 'bounds_changed', function() { update_results_in_view(true) });

    if (!place_bounds) {
      place_bounds = get_bounds(window.parishes);
    }

    if (callback) {
      callback();
    }
  }

  var get_bounds = function(parishes, start_location) {
    var bounds = new google.maps.LatLngBounds();

    if (start_location) {
      bounds.extend(
        new google.maps.LatLng(start_location.lat, start_location.lng)
      );
    }

    for (var i=0; i<parishes.length; i++) {
      parishes[i].lat = parseFloat(parishes[i].latitude);
      parishes[i].lng = parseFloat(parishes[i].longitude);

      bounds.extend(
        new google.maps.LatLng(parishes[i].lat, parishes[i].lng)
      );
    }
    return bounds;
  }

  var create_result_element = function(params) {
    return render_template("#result_template", {
      id:           params.id,
      type:         params.type,
      title:        params.type == 'parish' ? params.title + " - " + params.city : params.title,
      icon_classes: params.type == 'parish' ? 'icon-church text-yellow' : 'icon-map text-red'
    });
  }

  var build_search_url = function(query) {
    return '/search/?' + $('#search_form').serialize().replace(/query=[^&]*(?=&|$)/, 'query=' + (query || '*'))
  }

  var search_parishes = function(callback) {
    var query = get_query_search(),
        callback = !callback ? function(){} : callback;

    if (query.length == 0) {
      query = '*'

      // parish_results = [];
      // place_results = [];

      // update_results();
      // init_map(function(){
      //   update_markers();
      // });

      // return false;
    }

    if (!map) {
      init_map(function(){
        update_markers();
      });
    }

    $.get(build_search_url(query) + '&json=true', function(response){
      parish_results = response;
      parish_results = sort_parishes(parish_results, window.my_location);
      callback();
    }).fail(function(error){
      console.log(error);
    });
  }

  var search_places = function(callback) {
    var query           = get_query_search(),
        service         = new google.maps.places.AutocompleteService(),
        callback        = !callback ? function(){} : callback;

    if (!query || query.length < 2) {
      callback();
      return
    }

    service.getPlacePredictions({
      input: query,
      sessionToken: new google.maps.places.AutocompleteSessionToken(),
      bounds: place_bounds,
      strictBounds: true,
      componentRestrictions: {country: ['ca']},
      types: ['(regions)'] //['geocode']
    }, function(results, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        place_results = results;
      }

      if (place_results.length > 0 && parish_results.length < 5) {
        get_place_details(place_results[0].place_id, function(place){
          if (place) {
            var closest_parishes = sort_parishes(window.parishes, {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            });
            for (var i=0; i<5; i++) {
              if (closest_parishes[i] && closest_parishes[i].distance_from_location && closest_parishes[i].distance_from_location < 100000) {
                parish_results.push(closest_parishes[i]);
              }
            }
          }
          callback();
        });
      } else {
        callback();
      }
    });
  }

  var get_place_details = function(place_id, callback) {
    var service_details = new google.maps.places.PlacesService(map);
    service_details.getDetails({placeId: place_id, fields: ['geometry']}, function(place, status) {
      callback(place);
    });
  }

  var sort_parishes = function(parishes, location) {
    var distance = false;

    if (!parishes) return [];

    for (var i=0; i<parishes.length; i++) {
      parishes[i].lat = parseFloat(parishes[i].latitude);
      parishes[i].lng = parseFloat(parishes[i].longitude);

      if (location)  {
        distance = google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(location.lat, location.lng),
          new google.maps.LatLng(parishes[i].lat, parishes[i].lng)
        );
      }

      parishes[i].distance_from_location = (distance) ? distance : false;
    }

    parishes = parishes.sort(function(a, b) {
      if (a.distance_from_location && b.distance_from_location) {
        if (a.distance_from_location < b.distance_from_location) return -1;
        if (a.distance_from_location > b.distance_from_location) return 1;
      }
      return 0;
    });

    return parishes;
  }

  var update_results = function(params) {
    var places   = (params && params.places)   ? params.places   : place_results;
    var parishes = (params && params.parishes) ? params.parishes : parish_results;

    $('.search-spinner').hide();

    if (get_query_search()) {
      $('.search-clear').show();
    }

    update_markers();

    $('#search_results').html("");

    // TODO: Fix places to be more location aware
    // if (places && places.length > 0) {
      // for (var i = 0; i<3; i++) {
      //   if (!places[i]) continue;
      //   $('#search_results').append(
      //     create_result_element({
      //       id: places[i].place_id,
      //       title: places[i].description,
      //       type: 'place'
      //     })
      //   );
      // }
    // }

    if (parishes && parishes.length > 0) {
      for (var i=0; i<parishes.length; i++) {
        $('#search_results').append(
          create_result_element({
            id: parishes[i].id,
            title: parishes[i].parish_name,
            city: parishes[i].city,
            type: 'parish'
          })
        );
      }
    }

    if ($('#search_results').length > 0) {
      $(document).trigger('content-ready', $('#search_results'));
    }

    $(".sidebar .sidebar-inner").scrollTop(0);
    $(".sidebar").scrollTop(0);
    $(window).scrollTop(0);

    if ($("#search_results").html().length > 0) {
      $("#popular_results").hide();
    }
  }

  var update_results_in_view = function(dont_save){
    var map_bounds = map.getBounds();

    if (updating_markers) {
      updating_markers = false;
      return true;
    }

    // if (/* !dont_save && */ !window.current_parish_id) {
    //   if (window.location.pathname == '/') {
    //     console.log("FIXME: pushState('')");
    //     window.history.pushState({}, '', window.location.href);
    //   }
    //   var query = get_query_search() ? '?query=' + get_query_search() + '&' : '?';
    //   query+= 'zoom=' + map.getZoom() + '&lat=' + map.getCenter().lat() + '&lng=' + map.getCenter().lng();
    //   console.log("FIXME: replaceState(" + (window.location.origin + window.location.pathname + query) + ")");
    //   window.history.replaceState(window.history.state, '', window.location.origin + window.location.pathname + query);
    // }

    $('#search_results').html("");

    if (place_results.length > 0) {
      for (var i = 0; i<3; i++) {
        if (!place_results[i]) continue;
        $('#search_results').append(
          create_result_element({
            id: place_results[i].place_id,
            title: place_results[i].description,
            type: 'place'
          })
        );
      }
    }

    if (window.parishes.length > 0) {
      for (var i=0; i<window.parishes.length; i++) {
        if (!map_bounds.contains(new google.maps.LatLng(window.parishes[i].lat,window.parishes[i].lng))) continue;

        $('#search_results').append(
          create_result_element({
            id: window.parishes[i].id,
            title: window.parishes[i].parish_name,
            city: window.parishes[i].city,
            type: 'parish'
          })
        );
      }
    }

    if ($("#search_results").length > 0 && $("#search_results").html().length > 0) {
      $('#popular_results').hide();
    }

    if ($('#search_results').length > 0) {
      $(document).trigger('content-ready', $('#search_results'));
    }
  }

  var render_template = function(template, fields) {
    var template_html = $(template).clone().html();
    for (var key in fields) {
      template_html = template_html.split('{{' + key + '}}').join(fields[key]);
    }
    return template_html;
  }

  var update_markers = function(start_location) {
    var parishes = window.parishes;

    updating_markers = true;

    $("#map_loader").hide().addClass('z-20').fadeIn();

    // Clear markers
    for (var i = 0; i<markers.length; i++) {
      if (!markers[i]) continue;
      markers[i].setMap(null);
    }

    for (var i=0; i<parishes.length; i++) {
      var info_window_html = render_template("#infowindow_template", parishes[i]);

      markers[i] = new google.maps.Marker({
        position: {lat: parishes[i].lat, lng: parishes[i].lng},
        map: map,
        title: parishes[i].parish_name,
        icon: 'https://img.fortawesome.com/f086f62e/marker-gray.svg',

        // Add some custom params
        parish: parishes[i],
        index: i,
        infoWindow: new google.maps.InfoWindow({content: info_window_html, maxWidth: 200})
      });

      markers[i].addListener('click', function() {
        var parish = this.parish;

        if (window.current_parish_id && window.current_parish_id == parish.id) {
          this.infoWindow.open(map, this);
          return true;
        }

        if ($(window).width() > 991) {
          load_new_page({parish_id: parish.id}, "Parish Page", `/masstimes/parish/${parish.id}`, true);
        } else {
          var preview_html = render_template("#parish_preview_template", get_parish_by_id(parish.id));
          $("#parish_preview").html(preview_html);
          $(document).trigger('content-ready', $('#parish_preview'));
          show_view('parish-preview');
        }
      });

      if (window.current_parish_id && window.current_parish_id == parishes[i].id && $(window).width() > 991) {
        markers[i].infoWindow.open(map, markers[i]);
        map.panTo({lat: parishes[i].lat, lng: parishes[i].lng});
        map.setZoom(close_zoom);
        markers[i].infoWindow.open(map, markers[i]);
        markers[i]['icon'] = 'https://img.fortawesome.com/f086f62e/marker.svg';
      }

      if (parish_results.filter(function(p) { return p.id == parishes[i].id; }).length > 0) {
        markers[i]['icon'] = 'https://img.fortawesome.com/f086f62e/marker.svg';
        if (parish_results.length < 11) {
          markers[i].infoWindow.open(map, markers[i]);
        }
      }
    }

    if (!window.current_parish_id) {

      if (get_zoom() || get_coordinates()) {
        if (get_zoom()) {
          map.setZoom(get_zoom());
        }
        if (get_coordinates()) {
          map.panTo(get_coordinates());
        }
      } else if (parish_results.length || start_location) {
        if (!parish_results.length) {
          parish_results = window.parishes;
        }
        map.fitBounds(get_bounds(parish_results, start_location));
        if (map.getZoom() > close_zoom) {
          map.setZoom(close_zoom - 2);
        }
      }
    }

    $("#map_loader").fadeOut(function() { $(this).removeClass('z-20').show(); });
  }

  var get_item = function(type, id) {
    if (!id) {
      return false;
    }

    var endpoint = (type == 'parish' ? `/masstimes/parish/${id}` : `/masstimes/parish/${id}/masstime`);

    parish_results = window.parishes;
    $("#map_loader").hide().addClass('z-20').fadeIn();

    // for masstimes info (if we pull this in as well)
    // window.current_parish_id = $('#parish_results').find('#masstime-info').data('parish-id');
    // $('title').text($('#parish_results').find('#masstime-info').data('page-title') + " - Mass Finder");

    window.current_parish_id = id;
    $('title').text(get_parish_by_id(id).parish_name + " - Mass Finder");
    update_markers();
    $("#map_loader").fadeOut(function() { $(this).removeClass('z-20').show(); }); // ideally this would be after the ajax load completes, maybe on content-ready?
    show_view('parish');
    CanvasForms.ajaxUpdateContent('#parish_results', {}, endpoint);
  }

  var show_parish = function(parish_id) {
    get_item('parish', parish_id);
  }

  var show_masstime = function(masstime_id) {
    get_item('masstime', masstime_id);
  }

  var get_parish_by_id = function(id) {
    var parish = {};
    for (var i=0; i<window.parishes.length; i++) {
      if (window.parishes[i].id == id) {
        parish = window.parishes[i];
        break;
      }
    }
    return parish;
  }

  var get_query_search = function() {
    var value = /query=([^&]+)/.exec(window.location.href);
    return value && value[1] ? decodeURIComponent(value[1]).replace(/\+/g, " ") : '';
  }

  var get_zoom = function() {
    var value = /zoom=([^&]+)/.exec(window.location.href);
    return value && value[1] ? parseInt(value[1]) : false;
  }

  var get_coordinates = function() {
    var url = window.location.href,
        lat = /lat=([^&]+)/.exec(url),
        lat = lat && lat[1] ? parseFloat(lat[1]) : '',
        lng = /lng=([^&]+)/.exec(url),
        lng = lng && lng[1] ? parseFloat(lng[1]) : '';

    return lat && lng ? {lat: lat, lng: lng} : false;
  }

  var show_view = function(view_class) {
    var view_classes = [
      'parish',
      'parish-preview',
      'main',
      'search-preview',
      'home'
    ];

    if (view_class && $('#search_page').hasClass('show-' + view_class)) {
      return true;
    }

    for (var i=0; i<view_classes.length; i++) {
      $('#search_page').removeClass('show-' + view_classes[i]);
    }

    if (view_class && view_classes.indexOf(view_class) >= 0) {
      $('#search_page').addClass('show-' + view_class);
    }
  }

  var clear_search = function() {
    $("#search").val("").focus();
    handle_search();
    show_view();
    $("#popular_results").show();
  }

  var start_search = function() {
    $("#home_search").blur();
    init_map(function(){
      update_markers();
    });
    show_view();
    $('#search').focus();
    $("html,body").animate({scrollTop: 0});
  }

  var load_new_page = function(state, title, page_path, do_routing) {
    window.history.pushState(state, title, page_path);

    if (typeof ga_tracking_id !== 'undefined' && typeof gtag !== 'undefined') {
      gtag('config', ga_tracking_id, {'page_path': page_path});
    }

    if (do_routing) {
      router({state: state});
    }
  }

  var router = function(e) {
    if (
      (!e.state && window.location.pathname.indexOf('/search') == 0) ||
      (e.state && typeof e.state.query !== 'undefined' && !e.state.query)
    ) {
      clear_search();
      return false;
    }

    if (!e.state) {
      if (window.location.pathname == '/') {
        window.location.href = '/';
      }
      return false;
    }

    $("#search_back").hide();
    $("#search_back_history").show();

    if (e.state && e.state.parish_id) {
      show_parish(e.state.parish_id);
      return true;
    }

    if (e.state && e.state.masstime_id) {
      show_masstime(e.state.masstime_id);
      return true;
    }

    window.current_parish_id = false;
    show_view('search-preview');
    $("title").text("Search - Mass Finder");

    if (get_query_search()) {
      $("#search").val(get_query_search());
    }

    handle_search();
  }

  window.onpopstate = router;

  if (isMassfinderMapPage() && window.location.pathname !== '/') {
    window.onload = function() {
      init_map(function(){
        if (get_query_search()) {
          var query = get_query_search();
          $("#search").val(query);
          router({state: {query: query}});
          return true;
        }
        update_markers();
      });
    }
  }

})(jQuery);
