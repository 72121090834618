// ### Extension manifest files listed here
// ### import 'glass/manifest-frontend' imported directly from pack file, keep this line here commented out
import 'formbuilder/manifest-frontend'
import 'blog/manifest-frontend'
import 'feast/manifest-frontend'
import 'autocurate/manifest-frontend'
import 'event/manifest-frontend'
import 'emails/manifest-frontend'
import 'masstimes/manifest-frontend'

import GlassScroll from 'glass/modules/scroll'

window.GlassScroll = GlassScroll

// ### Custom modules for this site
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./modules-frontend-only/', true, /\.js$/));
