// This manifest file (frontend-pack.js) is included on all frontend pages.
//
// To add site / app specific modules, please override and modify:
//  - javascripts/manifest-custom-back-and-frontend.js (for frontend of site, but also backend UI [glass live editor])
//  - javascripts/manifest-frontend.js                 (only for front of site - ommitted from admin interface views)
//
// *************************************************************************
// * For full documentation of what is being included where, see:        *
// * https://app.tettra.co/teams/glasscanvas/pages/frontend#scss-file-structure     *
// *************************************************************************

import "glass/manifest-frontend"
import "manifest-custom-frontend"
import "glass/manifest-back-and-frontend"
import "manifest-custom-back-and-frontend"
