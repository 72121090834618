const _file = 'MassfinderInit';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var MassfinderInit = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    if (element && element.querySelector("#masstimes-schedule-viewer, #schedule-editor-container")) {
      document.dispatchEvent(new CustomEvent("re-init-mass-finder", {}));
    }
  });

  // Return API for other modules
  return {
  };
})(jQuery);
