const _file = "BrowserWarning";
const _double_include = $(window).data("included-" + _file);
$(window).data("included-" + _file, true);

$(() => {
  const $proceedLink = $('#proceed-link')
  const $browserWarningModal = $('div.browser-update-wrapper')
  if ($proceedLink.length && $browserWarningModal.length) {
    $proceedLink.on('click', () => {
      $browserWarningModal.hide();
    })
  }
});
